export enum PROMOBAR_SKINS {
  HOLIDAY = "holiday",
  HOLIDAY_GREEN = "holiday-green",
  DISCOUNT = "discount",
  HOLIDAY_BEIGE = "holiday-beige",
  INVERSE = "inverse",
  STANDARD = "standard",
}

export type A11yText = {
  previousMessage: string
  nextMessage: string
}

export type Message = {
  skin: PROMOBAR_SKINS
  content: string
  preceedingWord?: string
  copyIcon?: string
  abTestServerData?: {
    experimentKey: string
    variationKey: string
  }
  couponCode?: string
  source?: "map" | "coupon"
}

export type PromoBarConfiguration = {
  type: string
  tracking: string
}

export type Config = {
  staleTTL: number
  expiredTTL: number
}

export type CommsBarApiResponse = {
  config: Config
  a11yText: A11yText
  promobarConfiguration: PromoBarConfiguration
  messages: Message[]
  excludedPageTypes: string[]
  contentfulLinkWithoutCoupon: string | undefined
  contentfulLinkWithCoupon: string | undefined
}

export type MainProps = {
  tenant?: string
  locale?: string
  pageType?: string
  pageId?: string
  data?: CommsBarApiResponse
}

export type TrackingConfig = {
  eventName: string
  properties: {
    [k: string]: string
  }
  linkAttributes?: {
    [k: string]: string | number
  }
}

export enum Locale {
  deDE = 'de-de',
  esES = 'es-es',
  fiFI = 'fi-fi',
  nlNL = 'nl-nl',
  itIT = 'it-it',
  svSE = 'sv-se',
  nbNO = 'nb-no',
  ptPT = 'pt-pt',
  daDK = 'da-dk',
  frCA = 'fr-ca',
  esUS = 'es-us',
  enCA = 'en-ca',
  nlBE = 'nl-be',
  enIE = 'en-ie',
  frBE = 'fr-be',
  frFR = 'fr-fr',
  frCH = 'fr-ch',
  deAT = 'de-at',
  deCH = 'de-ch',
  itCH = 'it-ch',
  enAU = 'en-au',
  enNZ = 'en-nz',
  enSG = 'en-sg',
  enIN = 'en-in',
}
