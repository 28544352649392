import { getTracking } from "@vp/tracking"

import { TrackingConfig } from "../app/types"

const DELAY_BEFORE_NAVIGATING_IN_MS = 300

const stopNavigation = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => event.preventDefault()

export const trackEvent = (trackingConfig: TrackingConfig, event?: React.MouseEvent<HTMLElement, MouseEvent>, href?: string) => {
  if (event && href) {
    stopNavigation(event)
  }
  const { eventName, properties } = trackingConfig
  const tracking = getTracking()

  if (tracking && eventName) {
    tracking.track(eventName, properties)
    href && navigate(href)
  } else {
    const options: AddEventListenerOptions = { once: true }
    window.addEventListener(
      "trackingReady",
      () => {
        const tracking = getTracking()
        tracking?.track(eventName, properties)
        href && navigate(href)
      },
      options
    )
  }
}

const navigate = (href: string): void => {
  setTimeout(() => {
    window.location.href = href

  }, DELAY_BEFORE_NAVIGATING_IN_MS)
}
