import * as React from "react"
import { MessageCarousel } from "./MessageCarousel.js"
import { Locale, MainProps, PROMOBAR_SKINS } from "./types.js"
import { I18nProvider } from '../localization/LanguageContext'

export const Root: React.FC<MainProps> = ({ data, pageType, locale }) => {
  if (!data) {
    return null
  }
  if (pageType && data.excludedPageTypes.includes(pageType)) {
    return null
  }
  return (
    <I18nProvider defaultLocale={locale as Locale}>
      <MessageCarousel data={data} pageType={pageType ?? ""} />
    </I18nProvider>
  )
}
