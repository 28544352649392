import { Icon, Link, SwanPathTypeEnum, getSwanIconUrl } from "@vp/swan"
import parse, {
  DOMNode,
  Element,
  HTMLReactParserOptions,
  attributesToProps,
  domToReact,
} from "html-react-parser"
import React, { useState } from "react"
import { trackEvent } from "../utils/trackingUtils"
type ContentProps = {
  content: string
  preceedingWord?: string
  copyIcon?: string
}
export const Content = ({
  content,
  preceedingWord,
  copyIcon,
}: ContentProps) => {
  const [iconType, setIconType] = useState<"saveAsCopy" | "check">("saveAsCopy")
  const url = getSwanIconUrl(iconType, SwanPathTypeEnum.hashed)

  if (preceedingWord && copyIcon) {
    const regex = new RegExp(preceedingWord, "g")
    // Replace the matched part with the desired format
    content =
      content.includes(preceedingWord) && preceedingWord !== ""
        ? content.replace(
            regex,
            preceedingWord + ' <span class="code-text"></span>'
          )
        : content
  }

  const handleCopy = () => {
    if (!copyIcon) return
    if (iconType === "saveAsCopy") {
      const text = copyIcon.split(":")[1].trim()
      navigator.clipboard.writeText(text)

      setIconType("check")

      trackEvent({
        eventName: 'Coupon Copied',
        properties: {
          category: 'Coupons',
          label: text,
          coupon_name: text,
        }
      })

      setTimeout(() => {
        setIconType("saveAsCopy")
      }, 1500)
    }
  }

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element) {
        const attributes = domNode.attribs
        const props = domNode.attributes && attributesToProps(attributes)

        if (domNode.name === "a" && props.href) {
          return (
            <Link {...props} href={props.href as string} fontWeight="normal">
              {domToReact(domNode.children as DOMNode[], options)}
            </Link>
          )
        }

        if (domNode.name === "span" && domNode.attribs.class === "code-text") {
          return (
            <span style={{ cursor: "pointer" }}>
              {url && (
                <img
                  src={url}
                  onClick={handleCopy}
                  className="swan-icon swan-icon-skin-standard swan-icon-size-20p"
                />
              )}
            </span>
          )
        }
      }

      return domNode
    },
  }

  return <>{parse(content, options)}</>
  //   return <>{content}</>
}
