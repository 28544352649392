import { BoundedContent, Card } from '@vp/swan'
import React from 'react'

export type DebugContainerProps = {
    contentfulLinkWithoutCoupon: string | undefined
    contentfulLinkWithCoupon: string | undefined
}

export const DebugContainer: React.FC<DebugContainerProps> = ({ contentfulLinkWithoutCoupon, contentfulLinkWithCoupon }) => {
    return (
        <BoundedContent my={"4"}>
            <Card backgroundColor={"accent"}>
                <ul>
                    {
                        contentfulLinkWithoutCoupon && (
                            <li>
                                <strong>Promobar without coupon:</strong>
                                <div>Contentful Entry: <a href={contentfulLinkWithoutCoupon} target="_blank">Link to entry</a></div>
                            </li>
                        )
                    }
                    
                    {
                        contentfulLinkWithCoupon && (
                            <li>
                                <strong>Promobar with coupon:</strong>
                                <div>Contentful Entry: <a href={contentfulLinkWithCoupon} target="_blank">Link to entry</a></div>
                            </li>
                        )
                    }
                </ul>
            </Card>
        </BoundedContent>
    )
}
